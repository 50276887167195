
import {Vue, Component, Prop} from 'vue-property-decorator';
import {ListDataSource} from "@/data/List/ListDataSource";
import metaData from "./metaData.json"
import InlineFileUploader from "@/components/inline-file-uploader.vue";
import {mdiPen, mdiTrashCan} from "@mdi/js";
@Component({
  components: {InlineFileUploader}
})
export default class sectionEditor extends Vue{
  @Prop() information!:any
  @Prop() resume!:any
  $message: any;
  icons:any ={
    pen:mdiPen,
    trash:mdiTrashCan
  }
  dataSource:ListDataSource = new ListDataSource({
    className: this.information.className,
    config:{},
    items:this.resume[this.information.className + 's']
  })

  getDataSource(id: number) {
    return this.dataSource.getObjectDataSource(id);
  }

  getMetaData(name: string) {
    return this.dataSource.getMetaDataByName(name);
  }

  async add(){
    await this.dataSource.add({
      resumeId: this.resume.id,
      caption:"Новая секция" + this.dataSource.items.length,
    },true)
    this.$message("секция успешно добавлено");
  }

  async created(){
    this.dataSource.metadata = {properties: metaData.section}
  }
}
