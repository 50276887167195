
import {Vue, Component, Prop} from 'vue-property-decorator';
import {ObjectDataSource} from "@/data/Object/ObjecDatatSource";
import SectionEditor from "@/components/resume-editor/section-editor.vue"
import SectionItemEditor from "@/components/resume-editor/section-item-editor.vue"
import {
  mdiAccountBoxMultipleOutline, mdiAccountConvertOutline, mdiAtomVariant, mdiBookEducationOutline,
  mdiCalendar, mdiCertificateOutline, mdiFileDocumentCheck, mdiHeadCogOutline, mdiHumanMaleBoardPoll,
  mdiLicense, mdiNewspaper, mdiScriptTextKeyOutline, mdiTranslate
} from "@mdi/js";
@Component({
  components:{SectionEditor, SectionItemEditor}
})
export default class index extends Vue {
  @Prop() dataSource!:ObjectDataSource
  @Prop() personDataSource!:ObjectDataSource
  loaded:boolean = false;
  sectionInformations:any[] = [
    {
      id:1,
      caption:"Сертификаты",
      icon:mdiCertificateOutline,
      className:"certificateSection",
    },
    {
      id:2,
      caption:"Специализация",
      icon:mdiAccountBoxMultipleOutline,
      className:"professionalAreaSection",
    },
    {
      id:3,
      caption:"Ключевые навыки",
      icon:mdiScriptTextKeyOutline,
      className:"skillSection",
    },
    {
      id:4,
      caption:"Опыт",
      icon:mdiAccountConvertOutline,
      className:"experienceSection",
    },
    {
      id:5,
      caption:"Образование",
      icon:mdiBookEducationOutline,
      className:"educationSection",
    },
    {
      id:6,
      caption:"Научная исследовательская деятельность",
      icon:mdiAtomVariant,
      className:"scientificResearchActivitySection",
    },
    {
      id:7,
      caption:"Научная преподавательская деятельность",
      icon:mdiHumanMaleBoardPoll,
      className:"scientificTeachingActivitySection",
    },
    {
      id:8,
      caption:"Интересы",
      icon:mdiHeadCogOutline,
      className:"interestsSection",
    },
    {
      id:9,
      caption:"Участие в организациях/конференциях",
      icon:mdiCalendar,
      className:"participationSection",
    },
    {
      id:10,
      caption:"Публикации",
      icon:mdiNewspaper,
      className:"publicationSection",
    },
    {
      id:11,
      caption:"Патенты",
      icon:mdiFileDocumentCheck,
      className:"patentSection",
    },
    {
      id:12,
      caption:"Достижения",
      icon:mdiLicense,
      className:"achievementSection",
    },
    {
      id:13,
      caption:"Языки",
      icon:mdiTranslate,
      className:"languageSection",
    }
  ]
  $message: any;

  getSectionItem(information:any){
    const {className} = information;
    return className.charAt(0).toUpperCase() + className.slice(1) + 'Item'
  }

 async updateCache(){
    await this.dataSource.updateField({
      fieldName:"cache",
      fieldValue: {
        certificateSections: this.dataSource.model.certificateSections,
        professionalAreaSections: this.dataSource.model.professionalAreaSections,
        skillSections: this.dataSource.model.skillSections,
        experienceSections: this.dataSource.model.experienceSections,
        educationSections: this.dataSource.model.educationSections,
        scientificResearchActivitySections: this.dataSource.model.scientificResearchActivitySections,
        scientificTeachingActivitySections: this.dataSource.model.scientificTeachingActivitySections,
        interestsSections: this.dataSource.model.interestsSections,
        participationSections: this.dataSource.model.participationSections,
        publicationSections: this.dataSource.model.publicationSections,
        patentSections: this.dataSource.model.patentSections,
        achievementSections: this.dataSource.model.achievementSections,
        languageSections: this.dataSource.model.languageSections,
      }
    })
   this.$message("Кэш успешно обновлён")
   console.log(this.dataSource.model.cache)
  }
}
